import React from 'react';
import { useHistory } from 'react-router';

import { EvNoTenant } from '@evinced-private/ui-common';

import { EXTERNAL_HUBSPOT_SOURCE } from '../../consts/hubspot-consts';
import RoutesHelper from '../../helpers/RoutesHelper';
import { useUserTenant } from '../../providers/userTenantProvider/UserTenantProvider';
import AuthenticationService from '../../services/AuthenticationService';

export default (): JSX.Element => {
	const { tenant } = useUserTenant();
	const history = useHistory();
	if (tenant) {
		history.push(RoutesHelper.getHomepagePath());
		return null;
	}

	return (
		<EvNoTenant
			portalId={process.env.HUBSPOT_API_KEY}
			externalHubspotSource={EXTERNAL_HUBSPOT_SOURCE}
			userEmail={AuthenticationService.getUserEmail()}
			onSignUpWithEmail={() => history.push(RoutesHelper.getSignupPath())}
		/>
	);
};
