import { useCallback, useEffect, useMemo, useState } from 'react';

import { SeriesLineOptions } from '@evinced-private/ui-common';

import { calculateXAxisTickPositions } from '../../../../../helpers/charts/MultiLineChartXAxisHelper';
import {
	onMultiChartDataChange,
	onVisibilityToggle
} from '../../../../../services/TickPositionsService';
import {
	OnLegendItemClick,
	OnLegendItemClickEvent,
	SeriesVisibility
} from '../../../../../types/PerformanceOverTimeChart';

export const useTickPositions = (
	multiChartData: SeriesLineOptions[]
): { tickPositions: number[]; onLegendItemClick: OnLegendItemClick } => {
	const [seriesVisibility, setSeriesVisibility] = useState<SeriesVisibility[]>([]);

	useEffect(() => {
		setSeriesVisibility(onMultiChartDataChange(multiChartData));
	}, [multiChartData]);

	const tickPositions = useMemo(
		() => calculateXAxisTickPositions(multiChartData, seriesVisibility),
		[multiChartData, seriesVisibility]
	);

	const onLegendItemClick: OnLegendItemClick = useCallback(
		(event: OnLegendItemClickEvent) => {
			setTimeout(() => {
				setSeriesVisibility(onVisibilityToggle(event));
			}, 0);
		},
		[setSeriesVisibility]
	);

	return {
		tickPositions,
		onLegendItemClick
	};
};
