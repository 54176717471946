/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom';

import { InitSentry } from 'src/services/SentryService';

import analytics from './services/analytics/AnalyticsService';
import hubspotService from './services/analytics/HubspotService';
import AppContainer from './AppContainer';

import './index.css';

// Init analytics
analytics.initAnalytics();

// Init hubspot
hubspotService.initHubspotClient();

// Init Sentry
InitSentry();

console.log(`Current UI Version is: ${UI_VERSION}`);

// Type assertion for getElementById since we know 'root' exists
const rootElement = document.getElementById('root');
if (!rootElement) {
	throw new Error('Root element not found');
}

ReactDOM.render(<AppContainer />, rootElement);
