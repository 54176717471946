import { SeriesLineOptions } from '@evinced-private/ui-common';

import {
	OnLegendItemClickEvent,
	OnMultiChartDataChange,
	OnVisibilityToggle,
	SeriesVisibility
} from '../types/PerformanceOverTimeChart';

export const onMultiChartDataChange: OnMultiChartDataChange =
	(multiChartData: SeriesLineOptions[]) => (previousSeriesVisibilityState: SeriesVisibility[]) =>
		multiChartData.map(
			(series) =>
				({
					id: series.id,
					visible: previousSeriesVisibilityState.find((s) => s.id === series.id)?.visible ?? true
				}) as SeriesVisibility
		);

export const onVisibilityToggle: OnVisibilityToggle =
	(event: OnLegendItemClickEvent) => (previousSeriesVisibilityState: SeriesVisibility[]) => {
		return previousSeriesVisibilityState.map(
			(series) =>
				({
					...series,
					visible: series.id === event.target.userOptions.id ? event.target.visible : series.visible
				}) as SeriesVisibility
		);
	};
