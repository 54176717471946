import { SeriesOptionsType } from '@evinced-private/ui-common';

import { SeriesVisibility } from '../../types/PerformanceOverTimeChart';
import { millisToDays } from '../DateFormatHelper';

export const calculateXAxisTickPositions = (
	multiChartData: SeriesOptionsType[],
	seriesVisibility: SeriesVisibility[]
): number[] => {
	/*
			earliestDateTimeInMillis - data point furthest away from now
			latestDateTimeInMillis - data point closest to now
	*/
	const { earliestDateTimeInMillis, latestDateTimeInMillis } = multiChartData.reduce(
		(result, series) => {
			// Check if the series is visible based on `seriesVisibility`
			if (!seriesVisibility.find((s) => s.id === series.id)?.visible) {
				return result;
			}

			// Narrow the type of `series.data` and iterate over valid data points
			if ('data' in series && Array.isArray(series.data)) {
				for (const dataPoint of series.data) {
					// Check if the dataPoint is an object with an `x` property
					if (
						typeof dataPoint === 'object' &&
						dataPoint !== null &&
						'x' in dataPoint &&
						typeof dataPoint.x === 'number'
					) {
						if (dataPoint.x < result.earliestDateTimeInMillis) {
							result.earliestDateTimeInMillis = dataPoint.x;
						}
						if (dataPoint.x > result.latestDateTimeInMillis) {
							result.latestDateTimeInMillis = dataPoint.x;
						}
					}
				}
			}
			return result;
		},
		{ earliestDateTimeInMillis: Infinity, latestDateTimeInMillis: -Infinity }
	);

	const range = latestDateTimeInMillis - earliestDateTimeInMillis;

	// Handle cases where there’s no valid range
	if (range === 0 || range === -Infinity) {
		return [];
	}

	const rangeInDays = millisToDays(range);

	/*
			Value by which the ticks of the x axis will be incremented.
			We want to have at most 6 ticks on the x axis.
			If the range is less than 6 days, we will have a tick for each day.
	*/
	const tickIntervalInMillis = range / (rangeInDays < 6 ? rangeInDays : 6);

	/*
			We return an array of ticks to create the x axis.
			The first tick is the point in time of the earliest data point.
			then each consecutive tick is the previous tick plus the tick interval.
	*/
	return Array.from(
		{ length: rangeInDays < 6 ? rangeInDays + 1 : 7 },
		(_, i) => earliestDateTimeInMillis + i * tickIntervalInMillis
	);
};
