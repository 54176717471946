import React, { FC } from 'react';
import { Route, Router, Switch, useHistory } from 'react-router';

import NotFound404Page from 'src/components/pages/not-found-404-page/NotFound404Page';

import RoutesHelper from '../../helpers/RoutesHelper';

import HomePage from './HomePage';

const HomePageRouter: FC = () => {
	const history = useHistory();

	return (
		<Router history={history}>
			<Switch>
				<Route exact path={RoutesHelper.getHomepagePath()} component={HomePage} />
				<Route exact path={RoutesHelper.getDashboardPath()} component={HomePage} />
				<Route exact path={RoutesHelper.getPropertiesPagePath()} component={HomePage} />
				<Route path="*" component={NotFound404Page} />
			</Switch>
		</Router>
	);
};

export default HomePageRouter;
