import _ from 'lodash';

import { BarDatum, EvRaceBarDatum } from '@evinced-private/ui-common';

import { PieSlice } from 'src/types/PieChartTypes';

import { ComponentOverviewResponse, SeverityData } from '../types/OverviewTypes';

import MathHelper from './MathHelper';
import RoutesHelper from './RoutesHelper';

const LIMIT_RESULTS_BY = 10;
export const MAX_PERCENT_OF_ISSUES_TO_COVER = 70;

const getComponentsWithCriticalV2 = (
	componentsCountHash: ComponentOverviewResponse
): BarDatum[] => {
	return componentsCountHash.criticalComponents.map(({ component, issuesCount }) => ({
		id: component,
		count: issuesCount
	}));
};

const getTopTenComponents = (components): BarDatum[] => {
	const sorted = components.sort((a, b) => b.count - a.count);
	return sorted.splice(0, LIMIT_RESULTS_BY);
};

const getBarWidth = (size: number, totalCount: number): number => {
	return MathHelper.calcPercentage(size, totalCount, false);
};

const calcDataForComponentsRaceGraph = (
	topTenComponents,
	totalCriticalIssues,
	propertyId: string,
	scanId: string
): {
	barData: EvRaceBarDatum[];
	percentCovered: number;
} => {
	let percentCovered = 0;
	const barData: { chartData: EvRaceBarDatum; issueCount: number }[] = [];

	for (let i = 0; percentCovered < MAX_PERCENT_OF_ISSUES_TO_COVER; i++) {
		const component = topTenComponents[i];

		if (!component) {
			break;
		}

		const percentOfIssuesInComponent = MathHelper.calcPercentage(
			component.count,
			totalCriticalIssues,
			false
		);

		percentCovered += percentOfIssuesInComponent;

		if (percentCovered > 100) {
			break;
		}

		const url = RoutesHelper.getComponentDrilldownPath(propertyId, scanId, component.id);

		const item = {
			chartData: {
				id: component.id,
				percent: Math.round(percentOfIssuesInComponent),
				url
			},
			issueCount: component.count
		};

		barData.push(item);
	}

	const totalCovered = totalCriticalIssues * (percentCovered / 100);

	// adding data regarding the width that every item
	// should get based on its size inside the top-ten components
	barData.forEach((b) => {
		b.chartData.widthPercentageOnChart = getBarWidth(b.issueCount, totalCovered);
	});

	return {
		barData: barData.map((b) => b.chartData),
		percentCovered: _.sum(barData.map((b) => b.chartData.percent))
	};
};

const getEvincedAdvantage = (evincedCount: number, othersCount: number): string => {
	// can't devide by zero
	if (othersCount <= 0) {
		return '';
	}

	let advantage = evincedCount / othersCount;
	advantage = Math.floor(advantage);
	if (advantage <= 1) {
		return '';
	}

	return `x${advantage}`;
};

const IssueWithSeveritySeparator = '$$$';
const formatIssueWithSeverityId = (type: string, severity: string): string => {
	return `${type}${IssueWithSeveritySeparator}${severity}`;
};

const enrichIssueTypesWithPagesInfoV2 = (
	severityData: SeverityData,
	totalPages: number
): { id: string; percent: number; severity: string }[] => {
	return severityData.types.map((type) => {
		const percent = MathHelper.calcPercentage(type.pagesCount, totalPages);
		return {
			id: formatIssueWithSeverityId(type.type, severityData.severity),
			type: type.type,
			percent,
			severity: severityData.severity // used for coloring
		};
	});
};

const renderCustomTooltip = (context: PieSlice): string => {
	const { y, label } = context;
	return `<div style="display:flex;flex-direction:column;align-items:center;gap:10px;min-width: 50px">
					<div> ${label}</div>
					<div>${y}%</div>
				</div>`;
};

export default {
	getEvincedAdvantage,
	getComponentsWithCriticalV2,
	calcDataForComponentsRaceGraph,
	getTopTenComponents,
	enrichIssueTypesWithPagesInfoV2,
	renderCustomTooltip
};
